/* eslint-disable react/jsx-props-no-spreading */
import { defineMessages } from 'react-intl';
import Seo from '../../components/Seo/Seo';
import Post from '../../components/Post/Post';
import Layout from '../../components/layout';
import * as styles from './PrivacyPage.module.scss';

const messages = defineMessages({
    title: {
        id: 'meta.privacyTitle',
        defaultMessage: 'Cheap social media services | Cheap-Social.com',
    },
    description: {
        id: 'meta.privacyDescription',
        defaultMessage: 'Get more followers and likes for your social media profiles. We help you grow your social media presence overnight by increasing its organic reach.',
    },
    privacy: {
        id: 'privacy.main',
        defaultMessage: '<strong>Security and Confidentiality of the Information</strong><br />Cheap-Social.com respects the privacy of the user. Cheap-Social.com employs a sophisticated security system to make sure that confidential information is completely safe and uses it on the ‘need-to-know bases. The payment related information is encrypted with the help of the highly reliable technology SSL. Cheap-Social.com has taken all the possible steps to safeguard all the information of our users. Cheap-Social.com disclaims the liability in case of fails to maintain the security due to the cause beyond the reasonable control of Cheap-Social.com.<br /><br /><strong>Modifications</strong><br />Cheap-Social.com may change, modify or amend the Privacy Policy at any time without prior notice. The amendment will come into force immediately after it is published in our Terms of Services.',
    },
});

const PrivacyPage = () => (
    <Layout>
        <Seo title={messages.title} description={messages.description} />
        <div className={styles.container}>
            <Post dangerousHTML={messages.privacy} />
        </div>
    </Layout>
);

export default PrivacyPage;
